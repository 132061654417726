import React, { createContext, useReducer, useContext } from 'react';
import {
  getRandomWordsForDefaultTheme,
  generateGrid,
  getRandomWords,
  getCustomWords,
} from '../utils/utils';
import seedrandom from 'seedrandom';
import { wordThemes } from '../data/themes';
import { GRIDSIZE, directions } from '../data/constants';

const GameContext = createContext();

const gameReducer = (state, action) => {
  switch (action.type) {
    case 'START_GAME':
      const rng = seedrandom(action.seed);
      let randomWordsForTheme;
      const customWordArray = Array.isArray(action.customWord)
        ? action.customWord
        : action.customWord.split(',');
      if (action.theme === 'WordTheme') {
        if (action.wordTheme === 'Random') {
          randomWordsForTheme = getRandomWordsForDefaultTheme(rng, wordThemes);
        } else {
          const themeWords = wordThemes[action.wordTheme] || [];
          randomWordsForTheme = getRandomWords(themeWords, rng);
        }
      } else if (action.theme === 'CustomWords') {
        const customWordsToUse = customWordArray
          .filter((word) => word.trim() !== '')
          .map((word) => word.toUpperCase());
        randomWordsForTheme = getCustomWords(customWordsToUse, rng);
      }

      const newGrid = generateGrid(
        randomWordsForTheme,
        GRIDSIZE,
        directions,
        rng
      );

      return {
        ...state,
        gameStarted: true,
        seed: action.seed,
        theme: action.theme,
        wordTheme: action.wordTheme,
        customWord: customWordArray,
        isTimed: action.isTimed,
        timerActive: true,
        timeLimit: action.isTimed ? 60 : null,
        grid: newGrid,
        randomWords: randomWordsForTheme,
        foundWords: [],
        gameCompleted: false,
      };

    case 'UPDATE_TIMER':
      if (state.isTimed && state.timeElapsed + 1 >= state.timeLimit) {
        return {
          ...state,
          timeElapsed: state.timeElapsed + 1,
          gameCompleted: true,
          gameFailedDueToTimeout: true,
          timerActive: false, // Stop the timer
        };
      } else {
        return { ...state, timeElapsed: state.timeElapsed + 1 };
      }

    case 'FOUND_WORD':
      const updatedFoundWords = [
        ...state.foundWords,
        { word: action.word, start: action.start, end: action.end },
      ];
      const allWordsFound = state.randomWords.every((randomWord) =>
        updatedFoundWords.some((foundWord) => foundWord.word === randomWord)
      );
      return {
        ...state,
        foundWords: updatedFoundWords,
        gameCompleted: allWordsFound ? true : state.gameCompleted, // Update gameCompleted based on allWordsFound
      };

    case 'END_GAME':
      return {
        ...state,
        gameStarted: false,
        gameCompleted: true,
        timerActive: false,
      };

    case 'RESET_GAME':
      return {
        ...state,
        gameStarted: true,
        gameCompleted: false,
        gameFailedDueToTimeout: false,
        seed: seedrandom().quick().toString(),
        theme: state.theme,
        wordTheme: state.wordTheme,
        customWord: state.customWord,
        isTimed: action.isTimed,
        timerActive: action.isTimed,
        timeLimit: action.isTimed ? 60 : null,
        timeElapsed: 0,
      };
    default:
      return state;
  }
};

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const initialState = {
    gameStarted: false,
    gameCompleted: false,
    gameFailedDueToTimeout: false,
    seed: '',
    theme: '',
    wordTheme: '',
    customWord: '',
    isTimed: false,
    timeElapsed: 0,
    timerActive: false,
    timeLimit: null,
    foundWords: [],
    grid: [],
    randomWords: [],
  };

  const [state, dispatch] = useReducer(gameReducer, initialState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};
