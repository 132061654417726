import { useGame } from '../context/GameContext';

export default function WordList() {
  const { state } = useGame();
  const { randomWords, foundWords } = state;

  return (
    <ul className="word-list">
      {randomWords.map((word) => {
        const isFound = foundWords.some((foundWord) => foundWord.word === word);

        const listStyle = {
          textDecoration: isFound ? 'line-through' : 'none',
          color: isFound ? 'gray' : 'black',
        };

        return (
          <li key={word} style={listStyle}>
            {word}
          </li>
        );
      })}
    </ul>
  );
}
