export const wordThemes = {
  Cats: [
    'KITTEN',
    'PURR',
    'CLAW',
    'WHISKERS',
    'FUR',
    'TAIL',
    'LITTER',
    'SCRATCH',
    'MEOW',
    'ADOPT',
    'CURIOUS',
    'INDEPENDENT',
    'AGILE',
    'SLEEPY',
    'AFFECTIONATE',
    'PLAYFUL',
    'CLEVER',
    'GRACEFUL',
    'QUIET',
    'HUNTER',
  ],
  Dogs: [
    'PUPPY',
    'BARK',
    'LEASH',
    'FETCH',
    'TRAIN',
    'GROOM',
    'BREED',
    'KENNEL',
    'VACCINE',
    'ADOPT',
    'LOYAL',
    'OBEDIENT',
    'AGILE',
    'PLAYFUL',
    'FRIENDLY',
    'PROTECTIVE',
    'ENERGETIC',
    'SOCIAL',
    'INTELLIGENT',
    'TRAINABLE',
  ],
  Business: [
    'MARKETING',
    'ENTREPRENEUR',
    'FINANCE',
    'MANAGEMENT',
    'INVESTMENT',
    'STRATEGY',
    'LOGISTICS',
    'ECOMMERCE',
    'OUTSOURCING',
    'CONSULTING',
    'RETAIL',
    'COMMERCE',
    'BRANDING',
    'LEADERSHIP',
    'ANALYTICS',
    'ADVERTISING',
    'SALES',
    'NETWORKING',
    'DEVELOPMENT',
    'OPERATIONS',
  ],
  Education: [
    'TEACHER',
    'STUDENT',
    'CLASSROOM',
    'LESSON',
    'HOMEWORK',
    'SCHOOL',
    'STUDY',
    'LEARN',
    'TEXTBOOK',
    'CURRICULUM',
    'PRINCIPAL',
    'GRADE',
    'EXAM',
    'EDUCATE',
    'COLLEGE',
    'UNIVERSITY',
    'KNOWLEDGE',
    'TUTOR',
    'LECTURE',
    'SEMINAR',
  ],
  'Positive Affirmations': [
    'CONFIDENCE',
    'ACHIEVE',
    'SUCCESS',
    'BELIEVE',
    'POSITIVE',
    'HAPPINESS',
    'PROSPERITY',
    'FOCUS',
    'BALANCE',
    'COURAGE',
    'STRENGTH',
    'RESILIENCE',
    'OPTIMISM',
    'RESPECT',
    'GRATITUDE',
    'WELLNESS',
    'HEALTH',
    'KINDNESS',
    'LOVE',
    'PEACE',
  ],
  Engineering: [
    'STRUCTURE',
    'MECHANICS',
    'DESIGN',
    'CIVIL',
    'CHEMICAL',
    'ELECTRICAL',
    'ROBOTICS',
    'AUTOMATION',
    'BLUEPRINT',
    'CONSTRUCTION',
    'AEROSPACE',
    'BIOMEDICAL',
    'NUCLEAR',
    'SOFTWARE',
    'HARDWARE',
    'TECHNOLOGY',
    'INNOVATION',
    'MATERIALS',
    'COMPUTER',
    'NETWORK',
  ],
  'Computer Science': [
    'ALGORITHM',
    'DATABASE',
    'PROGRAM',
    'SOFTWARE',
    'HARDWARE',
    'NETWORK',
    'SECURITY',
    'DATA',
    'ANALYSIS',
    'DEVELOPER',
    'CODE',
    'PYTHON',
    'JAVA',
    'CPLUSPLUS',
    'JAVASCRIPT',
    'COMPILE',
    'DEBUG',
    'INTERFACE',
    'APPLICATION',
    'WEB',
  ],
  Chemistry: [
    'MOLECULE',
    'COMPOUND',
    'REACTANT',
    'ELECTRON',
    'ATOM',
    'SOLUTION',
    'CHEMICAL',
    'ACID',
    'BASE',
    'ALKALINE',
    'PERIODIC',
    'ELEMENT',
    'GAS',
    'LIQUID',
    'SOLID',
    'PLASMA',
    'IONS',
    'NEUTRON',
    'PROTON',
    'ORGANIC',
  ],
  Biology: [
    'CELL',
    'ORGANISM',
    'GENETICS',
    'EVOLUTION',
    'ECOLOGY',
    'ANATOMY',
    'SPECIES',
    'DNA',
    'PROTEIN',
    'MEMBRANE',
    'MITOCHONDRIA',
    'CHROMOSOME',
    'GENE',
    'BIOME',
    'HABITAT',
    'NUTRITION',
    'PLANT',
    'ANIMAL',
    'BACTERIA',
    'VIRUS',
  ],
  Physics: [
    'FORCE',
    'ENERGY',
    'MASS',
    'ACCELERATION',
    'VELOCITY',
    'MOTION',
    'ELECTRICITY',
    'MAGNETISM',
    'WAVE',
    'PARTICLE',
    'QUANTUM',
    'GRAVITY',
    'RELATIVITY',
    'POWER',
    'KINETIC',
    'POTENTIAL',
    'CHARGE',
    'FIELD',
    'RADIATION',
    'VACUUM',
  ],
  Medicine: [
    'DIAGNOSIS',
    'TREATMENT',
    'PHYSICIAN',
    'NURSE',
    'SURGERY',
    'VACCINE',
    'IMMUNITY',
    'PATHOLOGY',
    'ANATOMY',
    'SYMPTOM',
    'PRESCRIPTION',
    'MEDICATION',
    'THERAPY',
    'RECOVERY',
    'INFECTION',
    'DISEASE',
    'HEALTH',
    'VIRUS',
    'BACTERIA',
    'MICROBE',
  ],
  Psychology: [
    'COGNITION',
    'BEHAVIOR',
    'EMOTION',
    'PERCEPTION',
    'ATTITUDE',
    'LEARNING',
    'MEMORY',
    'THOUGHT',
    'MIND',
    'CONSCIOUSNESS',
    'STRESS',
    'ANXIETY',
    'DEPRESSION',
    'MOTIVATION',
    'PERSONALITY',
    'DEVELOPMENT',
    'THERAPY',
    'ADDICTION',
    'OBSERVATION',
    'INTERACTION',
  ],
  Music: [
    'MELODY',
    'HARMONY',
    'RHYTHM',
    'COMPOSITION',
    'ORCHESTRA',
    'CONCERT',
    'PERFORMANCE',
    'INSTRUMENT',
    'NOTE',
    'CHORD',
    'SCALE',
    'OCTAVE',
    'PIANO',
    'GUITAR',
    'DRUM',
    'VIOLIN',
    'FLUTE',
    'TRUMPET',
    'SYMPHONY',
    'OPERA',
  ],
  Animals: [
    'MAMMAL',
    'REPTILE',
    'BIRD',
    'FISH',
    'AMPHIBIAN',
    'INSECT',
    'SPECIES',
    'HABITAT',
    'ECOSYSTEM',
    'BIOME',
    'PREDATOR',
    'PREY',
    'DOMESTIC',
    'WILD',
    'HERBIVORE',
    'CARNIVORE',
    'OMNIVORE',
    'VERTEBRATE',
    'INVERTEBRATE',
    'ENDANGERED',
  ],
  Household: [
    'KITCHEN',
    'BATHROOM',
    'BEDROOM',
    'LIVINGROOM',
    'GARAGE',
    'BASEMENT',
    'ATTIC',
    'WINDOW',
    'DOOR',
    'FURNITURE',
    'APPLIANCE',
    'UTENSIL',
    'DECORATION',
    'CARPET',
    'CHAIR',
    'TABLE',
    'SHELF',
    'CABINET',
    'CLOSET',
    'LAMP',
  ],
  Dinosaurs: [
    'TYRANNOSAURUS',
    'VELOCIRAPTOR',
    'STEGOSAURUS',
    'TRICERATOPS',
    'BRACHIOSAURUS',
    'PTERODACTYL',
    'ALLOSAURUS',
    'SPINOSAURUS',
    'GIGANOTOSAURUS',
    'ANKYLOSAURUS',
    'HERBIVORE',
    'CARNIVORE',
    'EXTINCT',
    'FOSSIL',
    'CRETACEOUS',
    'JURASSIC',
    'TRIASSIC',
    'PALEONTOLOGY',
    'ASTEROID',
    'EVOLUTION',
  ],
};
