import { useNavigate } from 'react-router-dom';

export default function NewWordSearchButton() {
  const navigate = useNavigate();

  return (
    <button className="button-share-before" onClick={() => navigate('/')}>
      New Word Search
    </button>
  );
}
