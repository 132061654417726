import React, { useEffect } from 'react';
import { useGame } from '../context/GameContext';

export default function Timer() {
  const { state, dispatch } = useGame();
  const { timeElapsed, timerActive, gameCompleted, timeLimit } = state;

  useEffect(() => {
    let interval;

    if (
      timerActive &&
      !gameCompleted &&
      (timeLimit === null || timeElapsed < timeLimit)
    ) {
      interval = setInterval(() => {
        dispatch({ type: 'UPDATE_TIMER' });
      }, 1000);
    }

    // If the time limit is reached, end the game
    if (timeLimit !== null && timeElapsed >= timeLimit) {
      dispatch({ type: 'END_GAME' });
    }

    // Cleanup function to clear the timer interval
    return () => clearInterval(interval);
  }, [timeElapsed, timerActive, gameCompleted, timeLimit, dispatch]);

  const handleShareBeforeComplete = () => {
    const shareUrl = window.location.href;
    let shareText;
    if (window.location.search.includes('WordTheme')) {
      alert('Link copied to clipboard! Share it with your friends.');
      shareText = `Check out this word search puzzle! Good luck! ${shareUrl}`;
    } else if (window.location.search.includes('customWord')) {
      alert(
        'Link copied to clipboard! Share your Custom Words with your friends.'
      );
      shareText = `I made you a custom word search. Check it out! ${shareUrl}`;
    }

    // Call writeText inside a user-triggered event handler
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(shareText);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    // Call copyToClipboard when user clicks a button
    copyToClipboard();

    return shareText;
  };

  return (
    <section className="clock">
      <button
        onClick={handleShareBeforeComplete}
        className="button-share-before"
      >
        Share this word search with someone
      </button>
      <p>
        {state.timeLimit === null
          ? `${Math.floor(timeElapsed / 60)} : ${String(
              timeElapsed % 60
            ).padStart(2, '0')}`
          : `${Math.floor((state.timeLimit - timeElapsed) / 60)} : ${String(
              (state.timeLimit - timeElapsed) % 60
            ).padStart(2, '0')}`}
      </p>
    </section>
  );
}
