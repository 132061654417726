export default function GridCell({
  cell,
  rowIndex,
  cellIndex,
  isStartOfSelectedWord,
  isEndOfSelectedWord,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  getCellStyles,
}) {
  const cellClass = `cell ${isStartOfSelectedWord ? 'start' : ''} ${
    isEndOfSelectedWord ? 'end' : ''
  }`;

  const cellStyles = getCellStyles(rowIndex, cellIndex);

  return (
    <span
      className={cellClass}
      style={cellStyles}
      data-row={rowIndex}
      data-col={cellIndex}
      onMouseDown={() => onMouseDown(rowIndex, cellIndex)}
      onMouseUp={onMouseUp}
      onMouseMove={() => onMouseMove(rowIndex, cellIndex)}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {cell}
    </span>
  );
}
