import { wordThemes } from '../data/themes';
import { GRIDSIZE, directions } from '../data/constants';

// Get Random Words for Default Theme

function getRandomTheme(rng, wordThemes) {
  const themeNames = Object.keys(wordThemes);
  const randomIndex = Math.floor(rng() * themeNames.length);
  return themeNames[randomIndex];
}

function getRandomWordFromTheme(theme, rng, wordThemes) {
  const words = wordThemes[theme];
  const randomIndex = Math.floor(rng() * words.length);
  return words[randomIndex];
}

export function getRandomWordsForDefaultTheme(rng, wordThemes) {
  const words = [];
  for (let i = 0; i < 4; i++) {
    const theme = getRandomTheme(rng, wordThemes);
    words.push(getRandomWordFromTheme(theme, rng, wordThemes));
  }
  return words;
}

// getCustomword
export function getCustomWords(customWords, rng) {
  let customArray = [...customWords];
  return customArray;
}

// Get Random Words for Theme

export function getRandomWords(words, rng) {
  let array = [...words]; // Create a copy of the words array
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(rng() * (i + 1)); // Random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }

  return array.slice(0, 4); // Return the first 4 words
}

// cellsOfWord Function

export function cellsOfWord(startRow, startCol, grid, theme) {
  const words = wordThemes[theme];
  if (!words) {
    console.error(`No words found for theme: ${theme}`);
    return [];
  }

  for (let direction of directions) {
    let wordCells = [];
    let currentRow = startRow;
    let currentCol = startCol;
    let currentWord = '';

    while (
      currentRow >= 0 &&
      currentRow < GRIDSIZE &&
      currentCol >= 0 &&
      currentCol < GRIDSIZE
    ) {
      currentWord += grid[currentRow][currentCol];
      wordCells.push({ row: currentRow, col: currentCol });

      if (words.some((word) => word.startsWith(currentWord))) {
        if (words.includes(currentWord)) {
          return wordCells;
        }
      } else {
        break;
      }

      currentRow += direction.dy;
      currentCol += direction.dx;
    }
  }
  return [];
}

// Generate Grid

export function generateGrid(randomWords, gridSize, directions, rng) {
  let grid = [];
  let successfullyPlaced = [];
  let tries = 0;

  while (successfullyPlaced.length < randomWords.length && tries < 100) {
    grid = Array(gridSize)
      .fill(0)
      .map(() => Array(gridSize).fill('.'));
    successfullyPlaced = [];
    let failedToPlaceAWord = false;

    // Try to place actual words
    for (let i = 0; i < randomWords.length; i++) {
      const word = randomWords[i];
      if (!embedWordInGrid(grid, word, gridSize, directions, rng)) {
        failedToPlaceAWord = true;
        break;
      } else {
        successfullyPlaced.push(word);
      }
    }

    if (!failedToPlaceAWord) {
      // Now place trick words
      for (const word of successfullyPlaced) {
        const trickWords = generateTrickWords(word);
        for (const trickWord of trickWords) {
          // Try to place trick word, but do not overwrite existing words and do not place if it's the full word
          if (successfullyPlaced.includes(trickWord)) continue;
          embedWordInGrid(grid, trickWord, gridSize, directions, rng, true); // New parameter to indicate it's a trick word
        }
      }
      fillWithRandomLetters(grid, gridSize, rng);
      break;
    }

    tries++;
  }

  if (successfullyPlaced.length < randomWords.length) {
    console.warn(
      `Could only place ${successfullyPlaced.length} out of ${randomWords.length} words`
    );
  }

  return grid;
}

function hasSpaceForWord(grid, startRow, startCol, word, direction) {
  for (let i = 0; i < word.length; i++) {
    const x = startCol + i * direction.dx;
    const y = startRow + i * direction.dy;
    if (y < 0 || y >= GRIDSIZE || x < 0 || x >= GRIDSIZE) return false;
    const cell = grid[y][x];
    if (cell !== '.' && cell !== word[i]) {
      return false;
    }
  }
  return true;
}

function generateTrickWords(word) {
  const trickWords = [];
  for (let i = 1; i < word.length; i++) {
    trickWords.push(word.slice(0, -i));
  }

  return trickWords;
}

function embedWordInGrid(
  grid,
  word,
  gridSize,
  directions,
  rng,
  isTrickWord = false
) {
  let startRow,
    startCol,
    directionIndex,
    tries = 0;

  do {
    startRow = Math.floor(rng() * gridSize);
    startCol = Math.floor(rng() * gridSize);
    directionIndex = Math.floor(rng() * directions.length);
    tries++;
  } while (
    !hasSpaceForWord(
      grid,
      startRow,
      startCol,
      word,
      directions[directionIndex]
    ) &&
    tries < 500
  );

  if (tries >= 500) {
    if (!isTrickWord) {
      console.warn(`Could not place the word: ${word}`);
    }
    return false;
  }

  // If it's a trick word, make sure its placement doesn't overwrite any actual words
  if (
    isTrickWord &&
    !canPlaceTrickWord(
      grid,
      word,
      startRow,
      startCol,
      directions[directionIndex],
      gridSize
    )
  ) {
    return false;
  }

  for (let i = 0; i < word.length; i++) {
    const x = startCol + i * directions[directionIndex].dx;
    const y = startRow + i * directions[directionIndex].dy;
    grid[y][x] = word[i];
  }
  return true;
}

function canPlaceTrickWord(
  grid,
  word,
  startRow,
  startCol,
  direction,
  gridSize
) {
  for (let i = 0; i < word.length; i++) {
    const x = startCol + i * direction.dx;
    const y = startRow + i * direction.dy;
    if (
      y < 0 ||
      y >= gridSize ||
      x < 0 ||
      x >= gridSize ||
      grid[y][x] !== '.'
    ) {
      return false; // Cannot place trick word here as it would overwrite or go out of bounds
    }
  }
  return true; // Can place trick word
}

function fillWithRandomLetters(grid, gridSize, rng) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let row = 0; row < gridSize; row++) {
    for (let col = 0; col < gridSize; col++) {
      if (grid[row][col] === '.') {
        grid[row][col] = alphabet[Math.floor(rng() * alphabet.length)];
      }
    }
  }
}
