import { useState } from 'react';
import { useGame } from '../context/GameContext';
import GridCell from './GridCell';

export default function Grid() {
  const { state, dispatch } = useGame();
  const { grid, randomWords, foundWords } = state;

  const [selection, setSelection] = useState([]);

  function handleMouseDown(rowIndex, colIndex) {
    setSelection([{ row: rowIndex, col: colIndex }]);
  }

  function handleMouseMove(rowIndex, colIndex) {
    if (selection.length > 0) {
      const startCell = selection[0];
      const dx = colIndex - startCell.col;
      const dy = rowIndex - startCell.row;
      const isDiagonal = Math.abs(dy) === Math.abs(dx) && dx !== 0 && dy !== 0;

      const newSelection = [];
      if (isDiagonal) {
        for (let i = 0; i <= Math.abs(dx); i++) {
          newSelection.push({
            row: startCell.row + (dy >= 0 ? i : -i),
            col: startCell.col + (dx >= 0 ? i : -i),
          });
        }
      } else {
        const isHorizontal = Math.abs(dx) >= Math.abs(dy);
        if (isHorizontal) {
          for (let i = 0; i <= Math.abs(dx); i++) {
            newSelection.push({
              row: startCell.row,
              col: startCell.col + (dx >= 0 ? i : -i),
            });
          }
        } else {
          for (let i = 0; i <= Math.abs(dy); i++) {
            newSelection.push({
              row: startCell.row + (dy >= 0 ? i : -i),
              col: startCell.col,
            });
          }
        }
      }

      setSelection(newSelection);
    }
  }

  function handleMouseUp() {
    const currentWord = selection
      .map(({ row, col }) => grid[row][col])
      .join('');
    if (randomWords.includes(currentWord) && selection.length > 0) {
      const start = selection[0];
      const end = selection[selection.length - 1];
      dispatch({
        type: 'FOUND_WORD',
        word: currentWord,
        start: start,
        end: end,
      });
    }
    setSelection([]);
  }

  // Mobile deevices support
  function handleTouchStart(event) {
    event.preventDefault();
    const touch = event.touches[0];
    const element = document.elementFromPoint(touch.clientX, touch.clientY);
    const rowIndex = element.getAttribute('data-row');
    const colIndex = element.getAttribute('data-col');
    if (rowIndex && colIndex) {
      handleMouseDown(parseInt(rowIndex, 10), parseInt(colIndex, 10));
    }
  }

  function handleTouchMove(event) {
    event.preventDefault(); // Prevent scrolling when touching
    const touch = event.touches[0];
    const element = document.elementFromPoint(touch.clientX, touch.clientY);
    const rowIndex = element.getAttribute('data-row');
    const colIndex = element.getAttribute('data-col');
    if (rowIndex && colIndex) {
      handleMouseMove(parseInt(rowIndex, 10), parseInt(colIndex, 10));
    }
  }

  function handleTouchEnd(event) {
    event.preventDefault();
    handleMouseUp();
  }

  const cellIsPartOfWord = (wordCells, row, col) => {
    return wordCells.some((cell) => cell.row === row && cell.col === col);
  };

  const cellIsPartOfFoundWord = (row, col) => {
    return foundWords.some((found) => {
      const wordCells = cellsOfWord(
        found.start.row,
        found.start.col,
        found.end.row,
        found.end.col
      );
      return cellIsPartOfWord(wordCells, row, col);
    });
  };

  const getCellStyles = (row, col) => {
    let styles = {
      fontWeight: 'normal',
    };

    if (cellIsPartOfFoundWord(row, col)) {
      // Styles for found words
      styles.backgroundColor = 'var(--msw-green)';
      styles.fontWeight = 'bold';
      // styles.textDecoration = 'line-through';
    } else if (selection.some((s) => s.row === row && s.col === col)) {
      // Styles for current selection
      styles.backgroundColor = 'lightblue';
    }

    return styles;
  };

  function cellsOfWord(startRow, startCol, endRow, endCol) {
    const cells = [];
    const rowDirection = Math.sign(endRow - startRow);
    const colDirection = Math.sign(endCol - startCol);
    const distance = Math.max(
      Math.abs(endRow - startRow),
      Math.abs(endCol - startCol)
    );

    for (let i = 0; i <= distance; i++) {
      const row = startRow + i * rowDirection;
      const col = startCol + i * colDirection;
      cells.push({ row, col });
    }

    return cells;
  }

  function cellIsStartOfSelectedWord(rowIndex, cellIndex) {
    if (selection.length === 0) return false;
    const startCell = selection[0];
    return startCell.row === rowIndex && startCell.col === cellIndex;
  }

  function cellIsEndOfSelectedWord(rowIndex, cellIndex) {
    if (selection.length === 0) return false;
    const endCell = selection[selection.length - 1];
    return endCell.row === rowIndex && endCell.col === cellIndex;
  }

  return (
    <div className="word-grid">
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((cell, cellIndex) => (
            <GridCell
              key={cellIndex}
              cell={cell}
              rowIndex={rowIndex}
              cellIndex={cellIndex}
              isStartOfSelectedWord={cellIsStartOfSelectedWord(
                rowIndex,
                cellIndex
              )}
              isEndOfSelectedWord={cellIsEndOfSelectedWord(rowIndex, cellIndex)}
              isPartOfWord={cellsOfWord(rowIndex, cellIndex, grid).length > 0}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              getCellStyles={getCellStyles}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
