export default function CompletionMenu({
  restartGame,
  timeElapsed,
  handleShare,
  message,
}) {
  const handleRestart = (isTimed = false) => {
    restartGame(isTimed);
  };

  return (
    <div className="completion-menu">
      <h2>{message}</h2>
      <p>
        Time taken: {Math.floor(timeElapsed / 60)} :{' '}
        {String(timeElapsed % 60).padStart(2, '0')}
      </p>
      <button className="button button-green" onClick={handleRestart}>
        Start New Word Search
      </button>

      <button
        className="button button-blue"
        onClick={() => window.open('https://myawesomepuzzles.com', '_blank')}
      >
        See Our Books
      </button>

      <button className="button button-share" onClick={handleShare}>
        Copy Link & Challenge a Friend
      </button>
    </div>
  );
}
