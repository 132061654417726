import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGame } from '../context/GameContext';
import seedrandom from 'seedrandom';
import { wordThemes } from '../data/themes';

export default function SelectTheme() {
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedWordTheme, setSelectedWordTheme] = useState('Random');
  const [customWords, setCustomWords] = useState(['', '', '', '']);
  const navigate = useNavigate();
  const { dispatch } = useGame();

  const startGame = (isTimed) => {
    const seed = seedrandom().quick().toString();
    const customWordsString = customWords.join(',');

    dispatch({
      type: 'START_GAME',
      seed,
      theme: selectedTheme,
      wordTheme: selectedWordTheme,
      customWord: customWords,
      isTimed,
    });

    navigate(
      `/game?seed=${seed}&theme=${selectedTheme}&wordTheme=${selectedWordTheme}&customWord=${customWordsString}&isTimed=${isTimed}`
    );
  };

  const handleCustomWordChange = (index, value) => {
    const newCustomWords = [...customWords];
    newCustomWords[index] = value;
    setCustomWords(newCustomWords);
  };

  return (
    <section>
      <label htmlFor="theme-select" className="label">
        Pick Your Words:
      </label>
      <select
        id="theme-select"
        value={selectedTheme}
        onChange={(e) => setSelectedTheme(e.target.value)}
        className="theme-selector"
      >
        <option value="">Select Your Theme</option>
        <option value="WordTheme">Word Theme</option>
        <option value="CustomWords">Custom Words</option>
      </select>

      {selectedTheme === 'WordTheme' && (
        <>
          <br />
          <label htmlFor="theme-selected" className="label">
            Word Theme
          </label>
          <select
            id="theme-selected"
            value={selectedWordTheme}
            onChange={(e) => setSelectedWordTheme(e.target.value)}
            className="theme-selector"
          >
            <option value="Random">Random</option>
            {Object.keys(wordThemes).map((theme) => (
              <option key={theme} value={theme}>
                {theme}
              </option>
            ))}
          </select>
        </>
      )}
      {selectedTheme === 'CustomWords' && (
        <div>
          <br />
          <label className="label">Enter your Custom Words to Start Game</label>
          <br />
          <input
            type="text"
            placeholder="Enter custom word 1"
            value={customWords[0]}
            style={{ textTransform: 'uppercase' }}
            maxLength={15}
            pattern="[a-zA-Z]+"
            title="Please enter only alphabets (a-z, A-Z)"
            onChange={(e) => {
              const inputValue = e.target.value
                .toUpperCase()
                .replace(/[^A-Z]/g, '');
              handleCustomWordChange(0, inputValue);
            }}
            className="theme-input"
          />
          <input
            type="text"
            placeholder="Enter custom word 2"
            value={customWords[1]}
            style={{ textTransform: 'uppercase' }}
            maxLength={15}
            onChange={(e) => {
              const inputValue = e.target.value
                .toUpperCase()
                .replace(/[^A-Z]/g, '');
              handleCustomWordChange(1, inputValue);
            }}
            className="theme-input"
          />
          <br />
          <input
            type="text"
            placeholder="Enter custom word 3"
            value={customWords[2]}
            style={{ textTransform: 'uppercase' }}
            maxLength={15}
            onChange={(e) => {
              const inputValue = e.target.value
                .toUpperCase()
                .replace(/[^A-Z]/g, '');
              handleCustomWordChange(2, inputValue);
            }}
            className="theme-input"
          />
          <input
            type="text"
            placeholder="Enter custom word 4"
            value={customWords[3]}
            style={{ textTransform: 'uppercase' }}
            maxLength={15}
            onChange={(e) => {
              const inputValue = e.target.value
                .toUpperCase()
                .replace(/[^A-Z]/g, '');
              handleCustomWordChange(3, inputValue);
            }}
            className="theme-input"
          />
        </div>
      )}
      {selectedTheme && (
        <div className="button-wrapper">
          <button className="start-button" onClick={() => startGame(false)}>
            Start, Unlimited Time
          </button>
          <button className="start-button" onClick={() => startGame(true)}>
            Start, 60s Time
          </button>
        </div>
      )}
      {/* <div className="button-wrapper">
        <button className="start-button" onClick={() => startGame(false)}>
          Start, Unlimited Time
        </button>
        <button className="start-button" onClick={() => startGame(true)}>
          Start, 60s Time
        </button>
      </div> */}
    </section>
  );
}
