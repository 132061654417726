import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import seedrandom from 'seedrandom';
import { useGame } from '../context/GameContext';
import Timer from '../components/Timer';
import Grid from '../components/Grid';
import WordList from '../components/WordList';
import NewWordSearchButton from '../components/NewWordSearchButton';
import CompletionMenu from '../components/CompletionMenu';

export default function Game() {
  const navigate = useNavigate();
  const { state, dispatch } = useGame();
  const initialized = useRef(false);
  const { gameCompleted, gameFailedDueToTimeout, timeElapsed } = state;

  useEffect(() => {
    // Check if the game has already been initialized to prevent re-initialization
    if (!initialized.current && !state.gameStarted) {
      initialized.current = true; // Set to true to indicate initialization

      const query = new URLSearchParams(window.location.search);
      const seed = query.get('seed');
      const theme = query.get('theme') || 'Random';
      const wordTheme = query.get('wordTheme');
      const customWord = query.get('customWord');
      const isTimed = query.get('isTimed') === 'true';

      if (seed) {
        dispatch({
          type: 'START_GAME',
          seed,
          theme,
          wordTheme,
          customWord,
          isTimed,
        });
      }
    }
  }, [dispatch, state.gameStarted]);

  useEffect(() => {
    if (state.gameCompleted) {
      initialized.current = false;
    }
  }, [state.gameCompleted]);

  function restartGame(isTimed) {
    dispatch({
      type: 'RESET_GAME',
      isTimed: isTimed,
    });
    navigate('/');
  }

  function handleShare() {
    const shareUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    const shareText = `Check out this word search puzzle! ${shareUrl}`;

    // navigator.clipboard
    //   .writeText(shareText)
    //   .then(() => {
    //     alert('Link copied to clipboard! Share it with your friends.');
    //   })
    //   .catch((err) => {
    //     console.error('Could not copy link to clipboard', err);
    //   });

    if (navigator.clipboard) {
      // Modern approach with Clipboard API
      navigator.clipboard
        .writeText(shareText)
        .then(() =>
          alert('Link copied to clipboard! Share it with your friends.')
        )
        .catch((err) => console.error('Could not copy link to clipboard', err));
    } else {
      // Fallback using a temporary textarea
      const textArea = document.createElement('textarea');
      textArea.value = shareText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        alert('Link copied to clipboard! Share it with your friends.');
      } catch (err) {
        console.error('Fallback: Could not copy text', err);
      }
      document.body.removeChild(textArea);
    }
  }

  return (
    <main className="main-game">
      <Timer />
      <Grid />
      <WordList />
      <NewWordSearchButton />
      {gameCompleted && !gameFailedDueToTimeout && (
        <CompletionMenu
          restartGame={() => restartGame(false)}
          timeElapsed={timeElapsed}
          handleShare={() => handleShare(false)}
          message="Well done!"
        />
      )}
      {gameFailedDueToTimeout && (
        <CompletionMenu
          restartGame={() => restartGame(false)}
          timeElapsed={timeElapsed}
          handleShare={() => handleShare(true)}
          message="You ran out of time!"
        />
      )}
    </main>
  );
}
